body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--white-color);
}

:root {
  --white-color: #e5edf2;
  --yellow-color: #ffff00;
  --orange-color: #ffa915;
  --pink-color: #fb69b4;
  --red-color: #ff1919;
  --purple-color: #8a2be2;
  --blue-color: #1d92fb;
  --green-color: #16b84e;
  --black-color: #02040a;
  --navale-battle-color: #009bdb;
  --navale-battle-gradient-color: #00c5ff;
  --background-color: #0e1117;
  --description-color: #ffffffa1;
  --error-color: rgb(223, 37, 37);
  --disabled-color: #4d4b4b;
  --font-family: "__inter_20b187", "__inter_Fallback_20b187", ui-sans-serif,
    system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
