.containerContact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100dvh - 70px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.containerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: 25px 0;
  animation: fadeIn 1s;
}

.containerForm > *:not(:last-child) {
  margin-bottom: 30px;
}

.formTitle {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--white-color);
}

@media (max-width: 1023px) {
  .containerForm {
    width: 80%;
  }
}
