.containerInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerInput > *:not(:first-child) {
  margin-top: 10px;
}

label {
  font-size: 0.875rem;
  font-weight: 500;
}

input {
  font-family: var(--font-family);
  padding: 10px;
  border-radius: 8px;
  height: 20px;
  border: 1px solid var(--background-color);
}

input:focus {
  outline: none;
}

.inputErrorMessage {
  color: var(--error-color);
  margin-top: 5px !important;
}
