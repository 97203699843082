.containerTopBar {
  display: flex;
  width: 100vw;
  background-color: var(--black-color);
  height: 70px;
}

.link,
.topBarRight {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-left: 15px;
}

.linkChildren {
  margin-top: 5px;
}

.linkChildren::after {
  content: "";
  display: block;
  transform: scaleX(0);
  margin-top: 5px;
  border-bottom: 1px solid var(--orange-color);
  background: var(--orange-color);
  transition: transform 0.3s ease;
  transform-origin: center;
}

.linkChildren:hover::after {
  transform: scaleX(1);
}

.topBarRight {
  margin-left: auto;
  padding-right: 20px;
}

.containerLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 70px;
}

.logo {
  height: 40%;
}

.logo:hover {
  height: 50%;
}

@media (max-width: 1023px) {
  .link {
    margin-left: 10px;
  }

  .topBarRight {
    padding-right: 10px;
  }
}
