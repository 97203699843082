#containerToast {
  position: fixed;
  display: flex;
  align-items: center;
  margin-top: 30px;
  right: 30px;
  padding: 10px 20px;
  background-color: white;
  color: var(--background-color);
  border-radius: 4px;
  height: 35px;
  cursor: default;
  font-size: 0.9rem;
  visibility: hidden;
  border-left: 4px solid #3aaf3b;
}

.toastIcon {
  height: 20px;
  margin-right: 15px;
}

#containerToast.show {
  visibility: visible;
  -webkit-animation: fadeInToast 0.3s, fadeOutToast 0.3s 2.7s;
  animation: fadeInToast 0.3s, fadeOutToast 0.3s 2.7s;
}

@-webkit-keyframes fadeInToast {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 30px;
    opacity: 1;
  }
}

@keyframes fadeInToast {
  from {
    right: 0;
    opacity: 0;
  }
  to {
    right: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutToast {
  from {
    right: 30px;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}

@keyframes fadeOutToast {
  from {
    right: 30px;
    opacity: 1;
  }
  to {
    right: 0;
    opacity: 0;
  }
}
