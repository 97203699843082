.containerAbout {
  height: calc(100dvh - 70px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  cursor: default;
}

.about {
  display: flex;
  flex-direction: column;
  animation: fadeIn 1s;
  margin: 50px 8%;
}

.aboutImg {
  width: 200px;
  border-radius: 16px;
}

.aboutTop {
  display: flex;
  align-items: center;
}

.aboutTitle {
  color: var(--white-color);
  font-size: 1rem;
  font-weight: 500;
}

.aboutMe {
  margin-left: 50px;
}

.aboutTech,
.aboutPsychology {
  margin-top: 50px;
}

.aboutDescription {
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--description-color);
}

.aboutMe > *:not(:first-child),
.aboutTech > *:not(:first-child),
.aboutPsychology > *:not(:first-child) {
  margin-top: 10px;
}

.aboutSpecialWord {
  color: var(--white-color);
}

.aboutLink,
.linkExperience {
  color: var(--white-color);
  text-decoration: none;
}

.linkExperience {
  display: flex;
  align-items: center;
  height: 15px;
  width: 10rem;
  font-size: 0.875rem;
}

.linkExperience > *:not(:first-child) {
  margin-left: 5px;
}

.linkIcon {
  display: flex;
  width: 15px;
}

.aboutLink:hover,
.linkExperience:hover {
  color: var(--orange-color);
}

@media (max-width: 767px) {
  .about {
    margin: 35px 8%;
  }

  .aboutTop {
    flex-direction: column;
    align-items: flex-start;
  }

  .aboutMe {
    margin: 50px 0 0 0;
  }
}
