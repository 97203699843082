.containerTextarea {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.containerTextarea > *:not(:first-child) {
  margin-top: 10px;
}

textarea {
  font-family: var(--font-family);
  resize: vertical;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--background-color);
}

textarea:focus {
  outline: none;
}

.textareaErrorMessage {
  color: var(--error-color);
  margin-top: 5px !important;
}
