.containerExperience {
  position: relative;
  animation: fadeIn 1s;
  height: calc(100dvh - 70px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  cursor: default;
}

.resume {
  position: absolute;
  display: flex;
  color: var(--white-color);
  text-decoration: none;
  height: 20px;
  width: 11.2rem;
  right: 4%;
  top: 30px;
}

.resume > *:not(:first-child) {
  margin-left: 5px;
}

.resume:hover {
  color: var(--orange-color);
}

.containerPart {
  display: flex;
  margin: 65px 4%;
}

.containerPart > div {
  max-width: 50%;
}

.typeExperience {
  margin-left: calc(72px + 40px + 21px);
  margin-bottom: 20px;
  color: var(--white-color);
  line-height: 1.25;
  font-size: 1.5rem;
  font-weight: 500;
}

.containerInfos {
  display: flex;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

.containerInfos:hover {
  background-color: #191b20;
  transform: scale(1.05);
  border-radius: 6px;
}

.infos {
  padding: 20px;
  border-left: 1px solid var(--white-color);
}

.infoYear {
  padding: 20px;
  min-width: 72px;
  letter-spacing: 0.025em;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
}

.infoJob {
  font-size: 1rem;
  font-weight: 600;
}

.infoProject,
.infoDescription {
  font-size: 0.875rem;
  line-height: 1.5;
  margin: 20px 0;
}

.infoDescription {
  color: var(--description-color);
}

.infoSKill {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1023px) {
  .containerPart {
    flex-direction: column;
    margin: 50px 4%;
  }

  .containerPart > div {
    max-width: 100%;
  }

  .containerPart > div:not(:first-child) {
    margin-top: 50px;
  }

  .typeExperience {
    margin-left: calc(38px + 40px + 21px);
  }

  .infoYear {
    line-height: inherit;
    min-width: 38px;
  }
}

@media (max-width: 767px) {
  .resume {
    left: 8%;
  }
}
