.containerTag {
  padding: 5px 10px;
  background-color: #244158;
  border-radius: 20px;
  color: var(--white-color);
  margin: 5px;
  line-height: 1.25rem;
  font-size: 0.75rem;
  font-weight: 500;
}
