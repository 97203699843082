.containerHome {
  position: absolute;
  background-image: url("../../component/image/autumn.png");
  background-size: cover;
  background-position: center;
  animation: fadeIn 2s;
  width: 100%;
  height: calc(100dvh - 70px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.home {
  position: absolute;
  margin: 70px 10%;
  width: 80%;
}

.textHome {
  line-height: 1.25;
  font-size: 1.5rem;
  animation: fadeIn 2s;
}

@media (max-width: 767px) {
  .home {
    margin: 35px 10%;
  }
}
