button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 40px;
  border-radius: 8px;
  height: 45px;
  border: 1px solid var(--white-color);
  background-color: var(--background-color);
  cursor: pointer;
  font-size: 0.875rem;
  color: var(--white-color);
  max-width: 300px;
}

button:focus,
button:hover {
  filter: brightness(0.8);
  outline: none;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: none;
  border: 1px solid var(--disabled-color);
  color: var(--disabled-color);
}
